import anime from './node_modules/animejs/lib/anime.es.js';

/* ANIMEJS ANIMATIONS */

const sourceWaves = document.querySelector(".background .start").children;
const targetWaves = document.querySelector(".background .stop").children;
let wavesAnimations = [];

for (let i = 0; i < sourceWaves.length; i++) {
    const dStart = sourceWaves[i].getAttribute("d");
    const dEnd = targetWaves[i].getAttribute("d");
    const target = sourceWaves[i];
    wavesAnimations[i] = anime({
        targets: target,
        d: [{
            value: [
                dStart,
                dEnd,
            ]
        }],
        delay: (Math.random() * (100 - 50) + 50),
        easing: 'easeInOutSine',
        direction: 'alternate',
        duration: (Math.random() * (7000 - 1500) + 2500),
        loop: true,
        autoplay: false,
    });
}

const glowAnimation = () => {
    let tl = anime.timeline({
        targets: document.getElementById('glow'),
        easing: 'linear',
        direction: 'alternate',
        loop: false
    });
    tl.add({
        strokeOpacity: [0, Math.random(), Math.random(), Math.random()],
        duration: (Math.random() * (200 - 100) + 100),
    });
    tl.complete = () => { glowAnimation() };
};

glowAnimation();



const goalsTimeline = anime.timeline({
    autoplay: false,
    duration: 3200
});

goalsTimeline
    .add({
        targets: '.bounce .letter',
        translateY: [-70, 0],
        translateX: [-500, 0],
        opacity: [0.2, 0.8, 1],
        delay: anime.stagger(25),
        duration: 500,
        easing: 'easeOutSine'
    })
    .add({
        targets: '.progress-bar span',
        translateX: ['-100vw', 0],
        delay: anime.stagger(350),
        duration: 1500,
        easing: 'linear',
    }, '500');

/* OBSERVERS */

let observerCallback = (entries) => {
    entries.forEach((entry) => {
        if (entry.target.classList.contains("page1")) {
            headerAnimationObserverUtil(entry);
        }
        if (entry.target.classList.contains("quote")) {
            tilesAnimationObserverUtil(entry);
        }
        if (entry.target.classList.contains("background-heading")) {
            headingAnimationObserverUtil(entry);
        }
        if (entry.target.classList.contains("skills")) {
            skillsAnimationObserverUtil(entry);
        }
        if (entry.target.classList.contains("goals")) {
            goalsAnimationObserverUtil(entry);
        }
    });
}

let observer = new IntersectionObserver(observerCallback, { threshold: 0.1 });

const objectsObserve = (observerObject, ...nodes) => {
    for (const nodeList of nodes) {
        for (const node in nodeList) {
            if (Object.hasOwnProperty.call(nodeList, node)) {
                const element = nodeList[node];
                observerObject.observe(element);
            }
        }
    }
}

let header = document.querySelectorAll(".page1");
let quote = document.querySelectorAll(".quote");
let heading1 = document.querySelectorAll(".background-heading");
let skills = document.querySelectorAll(".skills.el");
let goals = document.querySelectorAll(".goals");
objectsObserve(observer, header, quote, heading1, skills, goals);

/* PARALLAX SCROLL */

const parallaxScrollingElements = document.querySelectorAll(".parallax-scroll");

window.addEventListener("scroll", () => { parallaxScrollAnimate() });

/* CONTROLS */

let openBtn = document.querySelector(".modal-btn");
let closeBtn = document.querySelector(".close-btn");
let modal = document.getElementById('contact-modal');

openBtn.addEventListener("click", () => {
    modal.style.visibility = "visible";
    modal.style.opacity = "1";
});
closeBtn.addEventListener("click", () => {
    modal.style.opacity = "0";
    setTimeout(() => { modal.style.visibility = "hidden" }, 250);

});

window.addEventListener('click', (e) => {
    if (e.isTrusted) {
        if (e.target !== modal && e.target !== openBtn) {
                modal.style.opacity = "0";
                setTimeout(() => { modal.style.visibility = "hidden" }, 250);
        }
    } else {
        return;
    }
});

const form = document.querySelector(".contact-form");

form.addEventListener( 'submit', (event) => {
    event.preventDefault();
    const XHR = new XMLHttpRequest();
    const formData = new FormData(form);
    // Define what happens on successful data submission
    XHR.addEventListener( "load", (e) => {
        alert( e.target.responseText );
    });
    
        // Define what happens in case of error
    XHR.addEventListener( "error", () => {
        alert( 'Oops! Something went wrong.' );
    });
    
        // Set up our request
    XHR.open( "POST", "form.php" );
    
    XHR.send(formData);
} )

/* CUSTOM ANIMATIONS */

function tilesAnimationObserverUtil(entry) {
    if (entry.isIntersecting) {
        window.addEventListener('mousemove', tilesAnimation);
    } else {
        window.removeEventListener('mousemove', tilesAnimation);
    }
}

let col = 5;
let row = 4;
let colsNo = getComputedStyle(document.querySelector(".page2")).getPropertyValue('--colsNo');

const tilesAnimation = (e) => {
    const grid = document.querySelector('.grid');
    let mouseX = e.clientX - grid.getBoundingClientRect().left;
    let mouseY = e.clientY - grid.getBoundingClientRect().top;

    let vw = window.innerWidth;
    let newCol = Math.floor(mouseX / ((0.05 * vw) + 5));
    let newRow = Math.floor(mouseY / ((0.05 * vw) + 5));

    if (newCol !== col || newRow !== row) {
        col = newCol;
        row = newRow;
        const tiles = document.querySelectorAll('.grid .el');

        for (let index = 0; index < tiles.length; index++) {
            const element = tiles[index];

            let x = Math.floor(index % colsNo);
            let y = Math.floor(index / colsNo);
            const deltaY = y - row;
            const deltaX = x - col;

            let hyp = Math.floor(Math.sqrt(Math.pow((deltaY), 2) + Math.pow((deltaX), 2)));
            let scale = (((colsNo) - hyp) / (colsNo));
            let angleRad = Math.atan2(deltaX, deltaY);
            let angleDeg = (angleRad) * (180 / Math.PI);

            element.style.transform = `scale(${scale}) rotate3d(0, 0.0, 1, ${180 - angleDeg}deg)`;
        }
    }
}

function parallaxScrollAnimate() {
    let scrolled = window.pageYOffset;
    let scale = scrolled * 0.009;
    scale = capNumber(0, scale, Infinity);
    
    for (const element of parallaxScrollingElements) {
        let rate = scrolled * element.dataset.rate;

        if (element.dataset.direction === 'scale') {
            element.style.transform = `scale(${scale})`;
        }
        if (element.dataset.direction === 'vertical') {
            element.style.transform = `translateY(${rate}px)`;
            element.style.webkitTransform = `translateY(${rate}px)`;
        } else if (element.dataset.direction === 'horizontal') {
            element.style.transform = `translateX(${rate}px)`;
            element.style.webkitTransform = `translateX(${rate}px)`;
        } else {
            let posX = window.pageYOffset * element.dataset.ratex;
            let posY = window.pageYOffset * element.dataset.ratey;
            element.style.transform = `translate(${posX}px, ${posY}px)`;
            element.style.webkitTransform = `translate(${posX}px, ${posY}px)`;
        }
    }
    
    function capNumber(min, number, max) {
        return Math.min(Math.max(number, min), max);
    }
}

/* UTIL FUNCITONS */

function goalsAnimationObserverUtil(entry) {
    if (entry.isIntersecting) {
        goalsTimeline.play();
    } else {
        goalsTimeline.reset();
    }
}

function skillsAnimationObserverUtil(entry) {
    let circles = entry.target.children[0].children;
    if (entry.isIntersecting) {
        for (const iterator of circles) {
            if (iterator.classList.contains('selective')) {
                iterator.classList.add("animation");
            }
        }
    } else {
        for (const iterator of circles) {
            if (iterator.classList.contains('selective')) {
                iterator.classList.remove("animation");
            }
        }
    }
}

function headingAnimationObserverUtil(entry) {
    let paths = entry.target.children[0].children;
    if (entry.isIntersecting) {
        for (const iterator of paths) {
            iterator.classList.add("animation");
        }
    } else {
        for (const iterator of paths) {
            iterator.classList.remove("animation");
        }
    }
}

let headerAnimationObserverUtil = (entry) => {
    if (entry.isIntersecting) {
        for (const wave of wavesAnimations) {
            wave.play();
        }
    } else {
        for (const wave of wavesAnimations) {
            wave.pause();
        }
    }
}